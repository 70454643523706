<template>
  <div
    v-if="!$apollo.loading"
    class="bg-page-dark user-venue-deal-page h-100 d-flex flex-column"
  >
    <b-container class="bg-white shadow-lg border p-0 flex-fill">
      <div class="p-2">
        <router-link
          :to="{ name: 'account' }"
          class="btn btn-outline-primary btn-sm"
        >
          <font-awesome-icon icon="arrow-left" fixed-width />
        </router-link>
      </div>
      <b-list-group v-if="reservations && reservations.length">
        <b-list-group-item
          v-for="booking in reservations"
          :key="booking.id"
          class="border-bottom m-0 rounded-0 row no-gutters border-left-0 border-right-0 border-top-0"
        >
          <b-row>
            <b-col cols="3" md="2">
              <b-img-lazy
                v-if="booking.venue.picture"
                :alt="booking.venue.name"
                :src="booking.venue.picture"
                fluid
              />
            </b-col>
            <b-col cols="9">
              <b-row class="h-100">
                <b-col cols="12" class="d-flex flex-column">
                  <h5
                    v-if="booking.venue && booking.venue.id"
                    class="text-primary text-uppercase font-weight-demi mb-1"
                  >
                    <router-link
                      :to="{
                        name: 'booking-venue',
                        params: { id: booking.venue.id }
                      }"
                    >
                      {{ booking.venue.name }}
                    </router-link>
                  </h5>
                  <small v-if="booking.venueServiceId">
                    <font-awesome-icon :icon="['far', 'clock']" fixed-width />
                    {{
                      booking.at.localeFormat
                    }}
                  </small>
                  <small v-else>
                    <font-awesome-icon :icon="['far', 'clock']" fixed-width />
                    {{ booking.at.localeFormat }}
                  </small>
                  <small>
                    <font-awesome-icon :icon="['fas', 'users']" fixed-width />
                    Pour {{ booking.guest }} personnes
                  </small>
                  <template v-if="booking.venueServiceId">
                    <div
                      v-if="booking.cancelledAt"
                      class="d-flex align-items-center"
                    >
                      <font-awesome-icon
                        icon="info-circle"
                        class="mr-2"
                      ></font-awesome-icon>
                      <b-badge variant="danger"> Annulée </b-badge>
                    </div>
                    <div v-else-if="booking.acceptedAt">
                      <div class="d-flex align-items-center">
                        <font-awesome-icon
                          icon="info-circle"
                          class="mr-2"
                        ></font-awesome-icon>
                        <b-badge variant="success"> Validée </b-badge>
                      </div>
                      <b-link
                        v-if="booking.isCancellableByUser"
                        class="mt-2"
                        variant="danger"
                        size="sm"
                        @click="cancel(booking)"
                      >
                        <small>
                          <font-awesome-icon
                            icon="times"
                            class="mr-2"
                          ></font-awesome-icon>
                          Annuler ma réservation
                        </small>
                      </b-link>
                    </div>
                    <div
                      v-else-if="booking.refusedAt"
                      class="d-flex align-items-center"
                    >
                      <font-awesome-icon
                        icon="info-circle"
                        class="mr-2"
                      ></font-awesome-icon>
                      <b-badge variant="danger"> Refusée </b-badge>
                    </div>

                    <div v-else>
                      <div class="d-flex align-items-center">
                        <font-awesome-icon
                          icon="info-circle"
                          class="mr-2"
                        ></font-awesome-icon>
                        <b-badge variant="warning">
                          En attente de validation
                        </b-badge>
                      </div>
                      <b-link
                        v-if="booking.isCancellableByUser"
                        class="mt-2"
                        variant="danger"
                        size="sm"
                        @click="cancel(booking)"
                      >
                        <small>
                          <font-awesome-icon
                            icon="times"
                            class="mr-2"
                          ></font-awesome-icon>
                          Annuler ma réservation
                        </small>
                      </b-link>
                    </div>
                  </template>
                  <div v-if="booking.needNote" class="mt-auto">
                    <div v-if="uploading">
                      <loader size="1x" />Envoie en cours
                    </div>
                    <b-form-file
                      v-show="!uploading"
                      v-model="file"
                      :state="Boolean(file)"
                      placeholder="Envoyer ma note"
                      @input="input(booking, ...arguments)"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>

      <div v-else class="p-2">
        <b-col cols="12" md="8" xl="6" offset-md="2" offset-xl="3">
          <div class="my-3">
            <font-awesome-layers class="fa-4x">
              <font-awesome-icon
                icon="circle"
                class="text-primary text-center"
              />
              <font-awesome-icon
                icon="calendar-times"
                class="text-white"
                transform="shrink-7"
                style="width: 64px"
              />
            </font-awesome-layers>
          </div>
          <h2 class="title">Vos réservations</h2>
          <p class="mb-5">Vous n'avez pas encore fait de réservations</p>
        </b-col>
      </div>
      <portal to="content">
        <transition name="swipe">
          <router-view />
        </transition>
      </portal>
      <b-modal
        v-if="currentBooking"
        id="cancelModal"
        ref="cancelModalRef"
        cancel-title="Retour"
        ok-title="Annuler ma réservation"
        @ok="doCancel"
        @hide="currentBooking = null"
      >
        Souhaitez-vous annuler votre réservation du
        <b>
          {{
            currentBooking.at.localeFormat
          }}
        </b>
        chez <b>{{ currentBooking.venue.name }} </b>
      </b-modal>
    </b-container>
  </div>
  <div v-else class="h-100 d-flex align-items-center justify-content-center">
    <font-awesome-icon :icon="['fas', 'spinner']" spin size="2x" />
  </div>
</template>
<script>
import RESERVATIONS from "@/graphql/auth/reservations.gql"
import STORE_RECEIPT from "@/graphql/mutations/StoreReceipt.gql"
import CANCEL_ONLINE_BOOKING from "@/graphql/mutations/CancelOnlineBooking.gql"
import Loader from "@/components/Loader"
export default {
  components: {
    Loader
  },
  data: () => ({
    file: null,
    uploading: false,
    currentBooking: null
  }),
  apollo: {
    reservations: {
      query: RESERVATIONS,
      client: "auth",
      fetchPolicy: "no-cache"
    }
  },
  methods: {
    input(booking) {
      this.uploading = true
      const file = this.file
      this.$apollo.mutate({
        mutation: STORE_RECEIPT,
        client: "auth",
        variables: {
          file,
          venueId: booking.venue.id,
          userVenueDealId: booking.userVenueDeal
            ? booking.userVenueDeal.id
            : null,
          reservationId: booking.id
        },
        update: (store, { data: { storeReceipt } }) => {
          this.uploading = false
          booking.needNote = false
        }
      })
    },
    cancel(booking) {
      this.currentBooking = booking
      this.$nextTick(() => {
        this.$refs.cancelModalRef.show()
      })
    },
    doCancel() {
      let booking = this.currentBooking
      this.$apollo.mutate({
        mutation: CANCEL_ONLINE_BOOKING,
        client: "auth",
        variables: {
          reservationId: this.currentBooking.id
        },
        update: (store, { data: { cancelOnlineBooking } }) => {
          booking.cancelledAt = cancelOnlineBooking.cancelledAt
        }
      })
    }
  }
}
</script>
